module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-analytics@4.21.0_gatsby@4.21.1_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"0","head":true,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.21.0_gatsby@4.21.1_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tech.inflab.com","short_name":"tech.inflab.com","description":"인프랩 기술 블로그","lang":"en","display":"standalone","start_url":"/","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"85c8af743e179883b18fef5acc3a66b0"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@6.21.0_gatsby-plugin-sharp@4.21.0_gatsby@4.21.1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":720,"linkImagesToOriginal":false,"backgroundColor":"transparent","showCaptions":["title"],"markdownCaptions":true,"quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-twitter@4.25.0_gatsby@4.21.1/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@5.21.0_gatsby@4.21.1_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-page-progress@2.2.1/node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"excludePaths":["/"],"height":3,"color":"#00c471","headerHeight":50},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-theme-material-ui-top-layout@5.2.0_@mui+material@5.10.2_gatsby@4.21.1/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.10.0_gatsby@4.21.1/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@5.21.0_gatsby@4.21.1_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.21.1_babel-eslint@10.1.0_react-dom@17.0.2_react@17.0.2_typescript@5.4.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
